<template>
  <div class="container with-sidebar">
    <Sidebar />
    <div>
      <h1>{{ $t('plants.zones.title') }}</h1>
      <div v-if="languageCode('es')">
        <p>Como se trata principalmente de un <router-link :to="{ name: 'concept' }">proyecto de restauración de
            ecosistemas</router-link>, nos centramos en restaurar el suelo para volverlo fértil y poder almacenar más
          agua. Eso es crucial ya que en nuestro clima tenemos una sequía anual que dura unos 6 meses.</p>
        <p>No toda la tierra es igual, por lo que trabajamos con un mayor número de zonas más pequeñas, cada una de las
          cuales recibe un tratamiento ligeramente diferente, tiene diferentes instalaciones y contiene diferentes
          comunidades de plantas.</p>
        <p>En la siguiente tabla puedes ver que zonas tenemos y cual es su uso actual. Si está disponible, también puede
          ver un informe más detallado para cada zona.</p>
        <p>Actualmente usamos {{ $n(managedSize() / 10000, 'decimal') }} hectáreas de 45 hectáreas de esta manera.</p>
      </div>
      <div v-else-if="languageCode('de')">
        <p>Da dies in erster Linie ein <router-link
            :to="{ name: 'concept' }">Ökosystem-Wiederherstellungsprojekt</router-link> ist, konzentrieren wir uns auf
          die Wiederherstellung des Bodens, um fruchtbar zu werden und mehr Wasser speichern zu können. Das ist
          entscheidend, da wir in unserem Klima eine jährliche Dürre haben, die etwa 6 Monate dauert.</p>
        <p>Nicht alle Flächen sind gleich und deshalb arbeiten wir mit einer größeren Anzahl kleinerer Zonen, die
          jeweils etwas anders behandelt werden, unterschiedliche Installationen haben und unterschiedliche
          Pflanzengemeinschaften enthalten.</p>
        <p>In der folgenden Tabelle können Sie sehen, welche Zonen wir haben und wie sie derzeit genutzt werden. Falls
          verfügbar, können Sie sich auch einen detaillierteren Bericht für jede Zone ansehen.</p>
        <p>Derzeit nutzen wir auf diese Weise {{ $n(managedSize() / 10000, 'decimal') }} Hektar von 45 Hektar</p>
      </div>
      <div v-else>
        <p>As this is primarily an <router-link :to="{ name: 'concept' }">ecosystem restoration project</router-link> we
          focus on restoring the soil in order to become fertile and be able to store more water. That is crucial as in
          our climate we have a yearly drought that lasts about 6 months.</p>
        <p>Not all the land is equal and so we work with a larger number of smaller zones that each receive a slightly
          different treatment, have different installations, and contain different plant communities.</p>
        <p>In the following table you can see what zones we have and what their current use is. If available, you can
          also
          look at a more detailed report for each zone.</p>
        <p>Currently we use {{ $n(managedSize() / 10000, 'decimal') }} hectares of 45 hectares in this way</p>
      </div>
      <table>
        <thead>
          <tr>
            <th>{{ $t('plants.zones.latestImage') }}</th>
            <th class="zone-name">{{ $t('plants.zones.zone') }}</th>
            <th class="zone-action">&nbsp;</th>
            <th class="zone-size">{{ $t('plants.zones.size') }}</th>
            <th class="zone-usage">{{ $t('plants.zones.usage') }}</th>
            <th class="last-access">{{ $t('plants.zones.lastAnimalAccess') }}</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="zone in sortedZones()" :key="zone.id">
            <td><router-link class="details-link" :to="{ name: 'zoneDetails', params: { zoneId: zone.id } }">
                <figure class="zone-image" v-if="zone.imageReferences">
                  <img :src="zone.imageReferences[zone.imageReferences.length - 1].thumbnailURL" />
                  <figcaption class="description short">{{
                    format(zone.imageReferences[zone.imageReferences.length -
                      1].when)
                  }}</figcaption>
                    <figcaption class="description long">{{ zone.zoneName }} ({{ zone.zoneUsage ? $t('plants.zones.usageType.' + zone.zoneUsage.replace(/\s/g, '').toLowerCase()) : '' }}): {{
            format(zone.imageReferences[zone.imageReferences.length - 1].when)
            }}</figcaption>
                </figure>
              </router-link>
            </td>
            <td class="zone-name">{{ zone.zoneName }}</td>
            <td class="zone-action">
              <router-link class="details-link" :to="{ name: 'zoneDetails', params: { zoneId: zone.id } }">
                <mdicon name="camera-image" />
              </router-link>
            </td>
            <td class="zone-size">{{ zone.zoneSize === undefined ? 0 : zone.zoneSize }} m&#x00B2;</td>
            <td class="zone-usage">{{ zone.zoneUsage ? $t('plants.zones.usageType.' + zone.zoneUsage.replace(/\s/g, '').toLowerCase()) : '' }}</td>
            <td class="last-access">{{ format(zone.accessClosed) }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import { getLanguage, formatDateTime } from '@/library'
import { axiosInstance } from '@/library'
import Sidebar from '@/views/concept/Sidebar.vue';

export default {
  name: 'Zones',
  components: {
    Sidebar,
  },

  data: () => ({
    zones: [],
  }),

  beforeMount () {
    this.listZones()
  },

  methods: {
    languageCode (code) {
      return code === getLanguage()
    },

    format (value) {
      return formatDateTime(this.$i18n, value)
    },

    sortedZones () {
      return this.zones.sort((a, b) => (a.zoneName > b.zoneName) ? 1 : ((b.zoneName > a.zoneName) ? -1 : 0))
    },

    managedSize () {
      let size = 0

      if (this.zones) {
        this.zones.forEach((zone) => {
          size += zone.zoneSize
        })
      }

      return size
    },

    listZones () {
      axiosInstance.get(`${window.VUE_APP_GRANJA}/api/zones`)
        .then(response => {
          if (response.status === 200) {
            this.zones = response.data.zones
          }
        })
        .catch(console.error)
    },
  }
}
</script>

<style scoped>
TH,
TD {
  text-align: left;
}

IMG {
  padding: 0 0.5em 0 0.5em;
}

.zone-action {
  padding-left: 0.5em;
  padding-right: 0.5em;
}

FIGURE.zone-image FIGCAPTION {
  font-weight: unset;
  font-size: unset;
  display: table-caption;
  caption-side: bottom;
  padding-bottom: 1em;
  text-align: center;
  color: black;
}

FIGCAPTION.description.long {
  display: none;
}

FIGCAPTION.description.short {
  display: block;
}

A.details-link {
  color: black;
  font-weight: unset;
  text-decoration: unset;
}

@media (max-width:440px) {
  .last-access {
    display: none;
  }

  .zone-size {
    display: none;
  }

  .zone-name {
    display: none;
  }

  .zone-usage {
    display: none;
  }

  FIGCAPTION.description.long {
    display: block;
  }

  FIGCAPTION.description.short {
    display: none;
  }

}
</style>
